import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import regeneratorRuntime from "/opt/build/repo/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { config, DefaultLocale, FakeDefaultLocale, getOrigin, Locale } from "@finn/ui-utils";
import axios from "axios";
import Cosmic from "cosmicjs";
import get from "lodash/get";
import values from "lodash/values";
import omitDeep from "omit-deep";
import { promiseTimeout } from "./promiseTimeout";
import { validateData } from "./validateData";
var timeoutLocal = 2000;
var timeoutProd = 10000;
var S3BaseUrl = "https://finn-website-assets.s3.eu-central-1.amazonaws.com/cosmicBackup";
export var getBackupCosmicObject = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(param) {
        var slug, locale, ref, data, response;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    slug = param.slug, locale = param.locale;
                    ;
                    if (locale === FakeDefaultLocale) {
                        locale = DefaultLocale;
                    }
                    data = {
                        objects: []
                    };
                    _ctx.prev = 4;
                    _ctx.next = 7;
                    return axios.get("".concat(S3BaseUrl, "/").concat(locale, "/").concat(slug, ".json"));
                case 7:
                    response = _ctx.sent;
                    data = response === null || response === void 0 ? void 0 : response.data;
                    _ctx.next = 14;
                    break;
                case 11:
                    _ctx.prev = 11;
                    _ctx.t0 = _ctx["catch"](4);
                    return _ctx.abrupt("return", null);
                case 14:
                    return _ctx.abrupt("return", JSON.parse(JSON.stringify(data === null || data === void 0 ? void 0 : (ref = data.objects) === null || ref === void 0 ? void 0 : ref[0])) || null);
                case 15:
                case "end":
                    return _ctx.stop();
            }
        }, _callee, null, [
            [
                4,
                11
            ]
        ]);
    }));
    return function getBackupCosmicObject(_) {
        return _ref.apply(this, arguments);
    };
}();
export function getCosmicObject(_) {
    return _getCosmicObject.apply(this, arguments);
}
function _getCosmicObject() {
    _getCosmicObject = _async_to_generator(regeneratorRuntime.mark(function _callee(param) {
        var _slug, slug, locale, _fields, fields, _showMetafields, showMetafields, _isFallbackCall, isFallbackCall, options, fallbackLocale, api, bucket, data, ref, response, backupObject, skimmedData;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _slug = param.slug, slug = _slug === void 0 ? "" : _slug, locale = param.locale, _fields = param.fields, fields = _fields === void 0 ? "type,metadata" : _fields, _showMetafields = param.showMetafields, showMetafields = _showMetafields === void 0 ? false : _showMetafields, _isFallbackCall = param.isFallbackCall, isFallbackCall = _isFallbackCall === void 0 ? false : _isFallbackCall;
                    if (locale === FakeDefaultLocale) {
                        locale = DefaultLocale;
                    }
                    options = {
                        slug: slug,
                        locale: locale,
                        fields: fields,
                        showMetafields: showMetafields,
                        isFallbackCall: false
                    };
                    fallbackLocale = "de";
                    api = Cosmic();
                    bucket = api.bucket({
                        slug: "finnauto",
                        read_key: config.COSMIC_BUCKET_READ_KEY
                    });
                    data = null;
                    _ctx.prev = 7;
                    ;
                    if (!(config.COSMIC_USE_S3 === "yes")) {
                        _ctx.next = 12;
                        break;
                    }
                    console.log("Not requesting cosmic as env var is set to use S3 directly");
                    throw new Error();
                case 12:
                    _ctx.next = 14;
                    return promiseTimeout(bucket.getObjects({
                        query: {
                            slug: options === null || options === void 0 ? void 0 : options.slug,
                            locale: options.locale
                        },
                        props: options.fields,
                        show_metafields: options.showMetafields,
                        status: config.APP_STAGE === "development" ? "any" : undefined
                    }), config.APP_STAGE === "development" ? timeoutLocal : timeoutProd);
                case 14:
                    response = _ctx.sent;
                    data = (ref = response.objects) === null || ref === void 0 ? void 0 : ref[0];
                    if (!data) {
                        console.log("Data for cosmic key does not exist ".concat(options === null || options === void 0 ? void 0 : options.slug, " ").concat(options.locale));
                    }
                    validateData(response);
                    _ctx.next = 39;
                    break;
                case 20:
                    _ctx.prev = 20;
                    _ctx.t0 = _ctx["catch"](7);
                    console.warn("Error while retrieving for cosmic key ".concat(options === null || options === void 0 ? void 0 : options.slug, " ").concat(options.locale, ": ").concat(JSON.stringify(_ctx.t0)));
                    _ctx.next = 25;
                    return getBackupCosmicObject({
                        slug: options === null || options === void 0 ? void 0 : options.slug,
                        locale: options.locale
                    });
                case 25:
                    backupObject = _ctx.sent;
                    if (!backupObject) {
                        _ctx.next = 29;
                        break;
                    }
                    console.log("Serving backup cosmic data from file for: slug:".concat(options === null || options === void 0 ? void 0 : options.slug, ", locale:").concat(options.locale));
                    return _ctx.abrupt("return", backupObject);
                case 29:
                    if (!(options.locale !== fallbackLocale && isFallbackCall || options.locale === Locale.GERMAN_GERMANY)) {
                        _ctx.next = 39;
                        break;
                    }
                    _ctx.prev = 30;
                    // second try with default locale
                    options.locale = fallbackLocale;
                    options.isFallbackCall = true;
                    return _ctx.abrupt("return", getCosmicObject(options));
                case 36:
                    _ctx.prev = 36;
                    _ctx.t1 = _ctx["catch"](30);
                    console.warn("Error while retrieving for cosmic key ".concat(options === null || options === void 0 ? void 0 : options.slug, " ").concat(options.locale, ": ").concat(JSON.stringify(_ctx.t1)));
                case 39:
                    skimmedData = omitDeep(data, [
                        "bucket",
                        "created_at",
                        "created_by",
                        "modified_at",
                        "created",
                        "status",
                        "published_at",
                        "modified_by",
                        "publish_at",
                        "unpublish_at",
                        "thumbnail", 
                    ]);
                    return _ctx.abrupt("return", JSON.parse(JSON.stringify(skimmedData)));
                case 41:
                case "end":
                    return _ctx.stop();
            }
        }, _callee, null, [
            [
                7,
                20
            ],
            [
                30,
                36
            ]
        ]);
    }));
    return _getCosmicObject.apply(this, arguments);
}
export var getCosmicObjectWithApi = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(params, req) {
        var options, data;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    options = {
                        method: "POST",
                        data: params,
                        url: "".concat(getOrigin(req), "/api/cosmic/getObject")
                    };
                    _ctx.prev = 1;
                    _ctx.next = 4;
                    return axios(options);
                case 4:
                    data = _ctx.sent.data;
                    return _ctx.abrupt("return", data);
                case 8:
                    _ctx.prev = 8;
                    _ctx.t0 = _ctx["catch"](1);
                    return _ctx.abrupt("return", null);
                case 11:
                case "end":
                    return _ctx.stop();
            }
        }, _callee, null, [
            [
                1,
                8
            ]
        ]);
    }));
    return function getCosmicObjectWithApi(params, req) {
        return _ref.apply(this, arguments);
    };
}();
export var buildCosmicPageObjectTree = function(data) {
    if (Array.isArray(data)) {
        return data.filter(function(item) {
            return Array.isArray(item) || (item === null || item === void 0 ? void 0 : item.id);
        }).map(function(item) {
            return buildCosmicPageObjectTree(item);
        });
    } else {
        var title = data.title || (data === null || data === void 0 ? void 0 : data.slug) || "No Title";
        var id = data.id;
        var type = data.type;
        var slug = data === null || data === void 0 ? void 0 : data.slug;
        var hasChildren = data === null || data === void 0 ? void 0 : data.metadata;
        var children = null;
        if (hasChildren) {
            children = buildCosmicPageObjectTree(values(data.metadata));
        }
        return {
            title: title,
            id: id,
            type: type,
            slug: slug,
            children: children
        };
    }
};
export var getInputPlaceholder = function(pageData, inputId) {
    return get(pageData, "metadata.input_fields.".concat(inputId, ".metadata.placeholder"), "");
};
export var getInputTooltipText = function(pageData, inputId) {
    var item = get(pageData, "metadata.input_hints", []).find(function(input) {
        return get(input, "metadata.key", "") === inputId;
    });
    return get(item, "metadata.information");
};
