export var BRAND = "finn.com";
export var PLP_SEO_HEADER_TEMPLATES = {
    BRAND_CARTYPES: "brand_cartype_plp",
    BRAND_MODELS: "brands_models_plp",
    BRAND_FUELTYPE: "brand_fueltype_plp",
    BRANDS: "brands_plp",
    CARTYPES: "cartypes_plp",
    FUELTYPE: "fueltype_plp",
    FALLBACK: "fallback_template_plp",
    REFERRAL: "referral_plp",
    ELECTRIC: "electric_plp",
    DEALS: "deals_plp",
    HITCH: "hitch_plp",
    YOUNG_DRIVER: "young_driver_plp"
};
export var DEFAULT_VEHICLES_LIMIT = 9;
export var TAX_RATE = 0.19;
export var VEHICLE_VAT = 1 + TAX_RATE;
export var FALLBACK_ADDITIONAL_MILEAGE_FEE = 0.25;
export var FALLBACK_TK_DEDUCTIBLE = 500;
export var DELIVERY_PLAN = 0;
export var errorMessages = {
    CAR_NOT_FOUND: "Car not found",
    CAR_NOT_FOUND_PROLONGATION: "Prolongation vehicle could not be fetched",
    CAR_NOT_AVAILABLE: "Car not available",
    AVAILABILITY_CHANGED: "Availability date has been changed",
    EMAIL_EXISTS: "Email already exists",
    TERM_NOT_AVAILABLE: "Term is not available",
    KM_PACKAGE_NOT_AVAILABLE: "KM Package is not available",
    DEAL_CLOSED_FOR_MISC_REASON: "Deal closed reason:",
    PRICE_NOT_AVAILABLE: "Price is not available",
    TERM_CHANGED: "Term is changed",
    PRICE_CHANGED: "Price is changed",
    EXPIRED_VOUCHER: "Voucher is not valid anymore",
    REFERRAL_VOUCHER_FAILED: "Referral voucher could not be applied",
    INVALID_VOUCHER: "Invalid Voucher",
    SERVER_ERROR: "Server Error",
    INCORRECT_IBAN: "Incorrect IBAN",
    STRIPE_NOT_LOADED: "Stripe Not Loaded",
    STRIPE_ERROR_ON_CHECKOUT_API: "Stripe Error On Checkout API",
    STRIPE_BANK_TRANSFER_FAILED: "Stripe Bank Transfer Failed",
    CONFIRM_STRIPE_INTENT_FAILED: "Confirm Stripe Intent Failed",
    RECONFIRMATION_SUBMISSION_FAILED: "Re-Confirmation submission to integromat webhook failed",
    DEAL_CREATION_FAILED: "Deal Creation Failed",
    CONTACT_UPDATE_FAILED: "Contact Update Failed",
    ADDRESS_SUBMISSION_FAILED: "Address Submission Failed",
    CREDIT_CHECK_FAILED: "Credit Check Failed",
    PAYMENT_FAILED: "Payment Failed",
    STRIPE_PAYMENT_FAILED: "Stripe Payment Failed",
    STRIPE_CREATE_PAYMENT_METHOD_FAILED: "Stripe Create Payment Method Failed",
    CONFIRMATION_FAILED: "Confirmation Failed",
    SANTANDER_B2B_FINANCIAL_FAILED: "Santander B2B Financial Failed",
    SANTANDER_B2C_FINANCIAL_FAILED: "Santander B2C Financial Failed",
    PAGE_LOAD_FAILED: "Page Load Failed",
    CART_NOT_AVAILABLE: "Cart Not Available"
};
export var DEFAULT_RETURN_PRICE = 199;
export var INSURANCE_DISCOUNT = 160;
export var SUBSCRIPTION_STATUS_LEVELS = {
    GRANULAR: "granular",
    DEFAULT: "overview"
};
export var AuthErrors;
(function(AuthErrors) {
    AuthErrors["USER_DOES_NOT_EXIST"] = "user_does_not_exist";
    AuthErrors["USER_ALREADY_EXISTS"] = "user_already_exists";
    AuthErrors["WRONG_PASSWORD"] = "wrong_password";
    AuthErrors["WRONG_CODE"] = "wrong_code";
})(AuthErrors || (AuthErrors = {}));
export var MarketingRoutes = {
    MY_LOYALTY_PROGRAM: "/loyalty"
};
export var ModalActionTypes;
(function(ModalActionTypes) {
    ModalActionTypes["AFFIRMATIVE"] = "affirmative";
    ModalActionTypes["NEGATIVE"] = "negative";
})(ModalActionTypes || (ModalActionTypes = {}));
export var DRIVER_TYPES;
(function(DRIVER_TYPES) {
    DRIVER_TYPES["PRIMARY"] = "primary";
    DRIVER_TYPES["SECONDARY"] = "secondary";
})(DRIVER_TYPES || (DRIVER_TYPES = {}));
export var DRIVER_STATUS;
(function(DRIVER_STATUS) {
    DRIVER_STATUS["ACTIVE"] = "active";
    DRIVER_STATUS["INACTIVE"] = "inactive";
    DRIVER_STATUS["DELETED"] = "deleted";
    DRIVER_STATUS["REJECTED"] = "rejected";
    DRIVER_STATUS["PENDING"] = "pending";
})(DRIVER_STATUS || (DRIVER_STATUS = {}));
export var ageBoundaries = {
    MAX_NORMAL: 75,
    MAX_NORMAL_US: 80,
    MIN_NORMAL: 23,
    MAX_EXCEPTIONAL: 80,
    MIN_EXCEPTIONAL: 18,
    MIN_EXCEPTIONAL_US: 25
};
export var REFERRAL_VOUCHER_COOKIE_EXPIRATION = 0.5; // Half day => 12h
export var LIMIT_VEHICLE_POWER_FOR_YOUNG_AGE = 150;
export var TEST_EMAIL_SUFFIX = "+test@finn.auto";
export var BUSINESSUNIT_ID = "5d1b57e91501f10001573745";
export var TIRES_SEASONAL = [
    "summer",
    "winter",
    "summer_winter"
];
export var TIRES_ALL_SEASON = "all_season";
export var TOP_NAV_ICON_SIZE = 110;
export var PRELOVED_LABEL = "Pre-loved";
export var SWR_CACHE_TIME = 60 * 1000; // 1min
export var SWR_CACHE_TIME_LONG = 10 * 60 * 1000; // 10min
export var BASE_SUBSCRIPTION_PATH = "subscribe";
export var FLEET_VIEW_DE = "available-and-coming-soon";
// export const FLEET_VIEW_DE = 'available_cars';
export var WINTER = "Winter";
export var IS_DIRECT_CHECKOUT_ENABLED = true;
export var NJ_EV_TAXT_EXEMPTION_EMPTY_FORM_FILE_URL = "https://finn-website-assets.s3.eu-central-1.amazonaws.com/st4.pdf";
export var TYPEFORM_SURVEY = {
    DE: "mIMqhwqp",
    US: "FNxo1qTP"
};
export var COMPARISON_SURVEY_WEBHOOK_URL = "https://hook.finn.integromat.cloud/mo5offy4yg57t4vukb6njjh4agcgoq1p";
export var ENABLE_FAKE_DOOR_LOGIN = false;
export var SECTION_HEADING_IDS = {
    CONTACT: "contact-section-heading",
    ADDRESS: "address-section-heading",
    DELIVERY: "delivery-section-heading",
    PAYMENT: "payment-section-heading",
    PAYMENT_ELEMENT: "stripe-payment-element",
    CONFIRMATION: "confirmation-section-heading",
    CONFIRMATION_COMBINED: "confirmation-submission-button"
};
export var SESSION_STORAGE_LAST_INVALID_EMAIL = "lastInvalidEmail";
export var RECAPTCHA_SITE_KEY = "6Lc88ywgAAAAAGd_RmVFnggUBIxTrGmjVOewFRrp";
// Set to true to enable Loyalty menu item. false value enables soft launch of Loyalty program where page is available but menu is hidden.
export var SHOW_LOYALTY_MENU_ITEM = true;
export var SERVER_DATE_FORMAT = "YYYY-MM-DD";
export var DEFAULT_DELIVERY_FEE = 249;
export var DEFAULT_DOWN_PAYMENT = 1200;
export var DEFAULT_DISCOUNT_PERCENTAGE = 3;
export var EXP_PROLONGATION_DISCOUNT_PERCENTAGE = 5;
export var EXP_PROLONGATION_DISCOUNT_MIN_TERM = 3;
export var DELIVERY_CONFIDENCE_THRESHOLD_WEEKS = 6;
