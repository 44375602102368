/* eslint-disable max-lines */
import { retentionOfferConfimed } from '@finn/cp-utils';
import { Deal } from '@finn/platform-modules';
import {
  CheckoutStep,
  clearServerCookie,
  setNextServerBooleanCookie,
} from '@finn/ua-auth';
import { VEHICLE_VAT } from '@finn/ua-constants';
import {
  clearClientCookie,
  CookieKeys,
  dateDiff,
  getDealId,
  getDealInfo,
  getLocaleFromContext,
  setClientBooleanCookie,
  traceabilityHeadersContext,
  twoDecimalPlaces,
  withLocaleRedirect,
} from '@finn/ui-utils';
import dayjs from 'dayjs';
import fromPairs from 'lodash/fromPairs';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import toPairs from 'lodash/toPairs';
import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import { NextRouter } from 'next/router';

import { restoreProcess } from '~/services/checkout';
import { HandoverDates } from '~/services/handover';
import {
  CheckoutServerSidePropsPayload,
  ContactInfo,
  DealInfo,
  NextStage,
} from '~/types/checkout';
import { PLPRoute } from '~/types/general';
import { Locale } from '~/types/localization';
import { NextServerSideContext } from '~/types/nextjs';
import { PaymentMethod } from '~/types/payment';
import { VoucherType } from '~/types/voucher';

import { GenericVehicleDetails } from '../contexts/Vehicle';
import {
  getVercelCookie,
  isBooleanCookieSet,
  removeNextAuthCookies,
} from './cookie';
import { isServer } from './general';
import { getServerSidePropsWithError } from './server/error';
import { addBusinessDays, isWithinRange } from './time';

const CREDIT_CHECK_REASON = 'Credit check';

export const stepsOrder = {
  contact: 0,
  payment: 1,
  confirmation: 2,
  financial: 3,
  thank_you: 4,
};

export { getDealInfo, getDealId };

export const removeGermanTax = (price: number) => price / VEHICLE_VAT;

const goToNextCheckoutStep = (
  ctx: NextServerSideContext,
  nextStage: string,
  dealData: DealInfo
) => {
  ctx?.res?.writeHead(302, {
    Location: withLocaleRedirect(
      `/checkout/${nextStage}/${dealData.contactId}/${dealData.dealId}/${dealData.hash}`,
      getLocaleFromContext(ctx)
    ),
  });
  ctx?.res?.end();
};

export const notAvailableCarRedirect = (
  ctx: NextServerSideContext,
  Router: NextRouter
) => {
  const PLPCurrentRoute = PLPRoute.SUBSCRIPTION;
  if (isServer()) {
    setNextServerBooleanCookie(
      ctx,
      CookieKeys.VEHICLE_CART_NOT_AVAILABLE,
      true
    );
    clearServerCookie(ctx, CookieKeys.CHECKOUT_DEAL_INFO);
    ctx?.res?.writeHead(302, {
      Location: withLocaleRedirect(PLPCurrentRoute, getLocaleFromContext(ctx)),
    });
    ctx?.res?.end();
  } else {
    clearClientCookie(CookieKeys.CHECKOUT_DEAL_INFO);
    Router.push(PLPCurrentRoute);
  }
};

export const pageNotAvailableRedirect = (
  ctx: NextServerSideContext,
  Router: NextRouter,
  url?: string
) => {
  if (isServer()) {
    setNextServerBooleanCookie(ctx, CookieKeys.PAGE_NOT_AVAILABLE, true);
    clearServerCookie(ctx, CookieKeys.CHECKOUT_DEAL_INFO);
    ctx?.res?.writeHead(302, {
      Location: withLocaleRedirect(
        url || '/subscribe',
        getLocaleFromContext(ctx)
      ),
    });
    ctx?.res?.end();
  } else {
    setClientBooleanCookie(CookieKeys.PAGE_NOT_AVAILABLE, true);
    clearClientCookie(CookieKeys.CHECKOUT_DEAL_INFO);
    Router.push(url || '/subscribe');
  }
};

export const correctCheckoutStepRedirect = (
  ctx: NextServerSideContext,
  Router: NextRouter,
  checkoutStep: string,
  currentStep: string
) => {
  if (checkoutStep) {
    // Check if the the booking has been submitted, i.e. user has passed the confirmation page
    const isUserBooked =
      stepsOrder[checkoutStep as NextStage] >
      stepsOrder[CheckoutStep.CONFIRMATION];

    const isOpenedPageAfterConfirmation =
      stepsOrder[currentStep as NextStage] >
      stepsOrder[CheckoutStep.CONFIRMATION];

    // Check if the available step order is less than current opened step then redirect to the available step page
    const isItEarlyStep =
      stepsOrder[checkoutStep as NextStage] <
      stepsOrder[currentStep as NextStage];

    // Ignore step check for pages after confirmation
    const isIgnoreRedirect =
      isUserBooked && isOpenedPageAfterConfirmation && !isItEarlyStep;
    if (!isIgnoreRedirect && (isItEarlyStep || isUserBooked)) {
      goToNextCheckoutStep(ctx, checkoutStep, getDealInfo(ctx));

      // to know if should I stop the next operations for the current page
      return true;
    }
  } else {
    notAvailableCarRedirect(ctx, Router);

    return true;
  }
};

export const getVehicleVat = (totalPrice: number, deposit: number) => {
  const amount = totalPrice - deposit;

  return twoDecimalPlaces(amount - amount / VEHICLE_VAT);
};

// todo: move the calculation to checkout api
export const applyVoucher = ({
  monthlyPriceWithoutVoucherB2C,
  voucherAmount,
  isApplied,
  voucherType,
  isForBusiness = false,
}) => {
  if (!isApplied) {
    return {
      monthlyPrice: isForBusiness
        ? removeGermanTax(monthlyPriceWithoutVoucherB2C)
        : monthlyPriceWithoutVoucherB2C,
      monthlyVat: isForBusiness
        ? 0
        : getVehicleVat(monthlyPriceWithoutVoucherB2C, 0),
    };
  }

  // apply voucher
  const discountedPriceB2C = monthlyPriceWithoutVoucherB2C - voucherAmount;
  const discountedPrice = isForBusiness
    ? removeGermanTax(discountedPriceB2C)
    : discountedPriceB2C;
  if (voucherType === VoucherType.RELATIVE) {
    return {
      monthlyPrice: discountedPrice,
      monthlyVat: isForBusiness ? 0 : getVehicleVat(discountedPrice, 0),
    };
  } else {
    const monthlyVat = isForBusiness
      ? 0
      : getVehicleVat(monthlyPriceWithoutVoucherB2C, 0);
    const firstMonthVat = isForBusiness ? 0 : getVehicleVat(discountedPrice, 0);

    return {
      monthlyPrice: isForBusiness
        ? removeGermanTax(monthlyPriceWithoutVoucherB2C)
        : monthlyPriceWithoutVoucherB2C,
      monthlyVat,
      firstMonthPrice: discountedPrice,
      firstMonthVat,
    };
  }
};

export { getContractTermType } from '@finn/ui-utils';

export const getCheckoutCartUrl = (
  vehicleId: string,
  term: number | string,
  kmPackage: number | string,
  downPaymentAmount?: number
): string => {
  if (downPaymentAmount) {
    return `/checkout/cart/${vehicleId}/${term}/${kmPackage}?downPaymentAmount=${downPaymentAmount}`;
  }

  return `/checkout/cart/${vehicleId}/${term}/${kmPackage}`;
};

export const addressFields = [
  'firstname',
  'lastname',
  'street',
  'housenumber',
  'zipcode',
  'city',
  'state',
];

export const checkUsingContactInfoAsDeliveryAddress = (
  contactInfo: ContactInfo
) => {
  const shouldFillFields = !!contactInfo.firstname;

  const addressFieldsLocalized = addressFields.filter(
    (item) => item !== 'state'
  );

  if (shouldFillFields) {
    const contactAddress = pick(contactInfo, addressFieldsLocalized);
    const deliveryAddress = pick(
      contactInfo.deliveryAddress,
      addressFieldsLocalized
    );

    return !deliveryAddress.street || isEqual(contactAddress, deliveryAddress);
  } else {
    return true;
  }
};

type BlockNext7DaysProp = {
  carHandoverAvailability: HandoverDates | undefined;
  deposit: number;
  paymentMethod?: string;
};

const removePastDays = (
  carHandoverAvailability: HandoverDates
): HandoverDates => {
  const beginningOfUnixTime = new Date(0);
  const today = new Date();
  const datePairs = toPairs(carHandoverAvailability);
  const availabilityList = datePairs.filter(
    (deliveryDate: [string, { delivery_fee: number }]) => {
      const handoverDate = dayjs(deliveryDate[0]).toDate();

      return !isWithinRange(handoverDate, beginningOfUnixTime, today);
    }
  );

  return fromPairs(availabilityList) as unknown as HandoverDates;
};

export const blockNext7HandoverDays = ({
  carHandoverAvailability,
  deposit,
  paymentMethod,
}: BlockNext7DaysProp) => {
  carHandoverAvailability = removePastDays(carHandoverAvailability);
  // block next 7 business day if payment method is ACH or deposit is set
  const shouldBlock = deposit || PaymentMethod.ACH === paymentMethod;
  if (!shouldBlock) {
    return {
      hasBlockedAnyHandoverDates: false,
      handoverAvailability: carHandoverAvailability,
    };
  }

  const today = dayjs().toDate();
  const next7DaysEnd = addBusinessDays(today, 8);

  const datePairs = toPairs(carHandoverAvailability);
  const availabilityList = datePairs.filter(
    (deliveryDate: [string, { delivery_fee: number }]) => {
      const handoverDate = dayjs(deliveryDate[0]).toDate();

      return !isWithinRange(handoverDate, today, next7DaysEnd);
    }
  );

  return {
    hasBlockedAnyHandoverDates: availabilityList.length < datePairs.length,
    handoverAvailability: fromPairs(
      availabilityList
    ) as unknown as HandoverDates,
  };
};

export const getCreditScorePageUrl = (
  dealInfo: DealInfo,
  locale: Locale
): string => {
  const url = `checkout/credit-score/${dealInfo.contactId}/${dealInfo.dealId}/${dealInfo.hash}`;

  return locale === Locale.ENGLISH_USA ? `/${locale}/${url}` : `/${url}`;
};

export const CheckoutServerSidePropsWrapper = (GetServerSidePropsFn) => {
  const wrappedFn: GetServerSideProps = async (
    ctx: GetServerSidePropsContext
  ) => {
    try {
      // checkout/credit-score

      const requestHeaders = removeNextAuthCookies(ctx?.req?.headers);
      const dealInfo = getDealInfo(ctx);
      const locale = getLocaleFromContext(ctx);
      const isE2ETest = isBooleanCookieSet(
        ctx,
        CookieKeys.E2E_TEST_IN_PROGRESS
      );

      const cookie = getVercelCookie(ctx);

      const restoreProcessResponse = await restoreProcess(
        dealInfo,
        {
          cookie,
        },
        locale,
        isE2ETest,
        traceabilityHeadersContext(ctx)
      );

      if (restoreProcessResponse.status !== 200) {
        return await getServerSidePropsWithError(
          ctx,
          restoreProcessResponse.data,
          restoreProcessResponse.requestId
        );
      }

      const {
        checkoutStep,
        cart: cartInfo,
        contact: contactInfo,
        payment: paymentInfo,
        confirmation: confirmationInfo,
        financial: financialInfo,
        thank_you: thankYouInfo,
        prolongation: prolongationInfo,
        isCreditScoreValid,
        isDealClosed: dealClosedInfo,
      } = restoreProcessResponse;

      // Collecting common data needed and passing as payload to the pages
      const payload: CheckoutServerSidePropsPayload = {
        checkoutStep,
        cartInfo,
        contactInfo,
        dealInfo,
        paymentInfo,
        confirmationInfo,
        financialInfo,
        thankYouInfo,
        locale,
        requestHeaders,
        prolongationInfo: prolongationInfo || null,
      };

      // Check for blacklist or closed deal due to credit check
      const url = ctx.resolvedUrl;
      const isAlreadyRedirected = url.startsWith('/checkout/credit-score');
      const isDealClosed = dealClosedInfo?.value;
      const isClosedDueToCreditCheck =
        dealClosedInfo?.reason === CREDIT_CHECK_REASON;
      const isDealClosedForBadScore =
        (!isCreditScoreValid || isClosedDueToCreditCheck) && isDealClosed;
      const dealClosedMiscReason = !isDealClosedForBadScore && isDealClosed;

      const isBlacklisted = cartInfo?.isBlacklisted;
      const shouldShowCreditScorePage =
        isDealClosedForBadScore || isBlacklisted;
      if (!isAlreadyRedirected && shouldShowCreditScorePage) {
        return {
          redirect: {
            destination: getCreditScorePageUrl(dealInfo, locale as Locale),
            permanent: false,
          },
        };
      }
      if (dealClosedMiscReason) {
        setNextServerBooleanCookie(ctx, CookieKeys.DEAL_CLOSED, true);

        return {
          redirect: {
            destination: withLocaleRedirect(
              PLPRoute.SUBSCRIPTION,
              getLocaleFromContext(ctx)
            ),
            permanent: false,
          },
        };
      }

      // Execute the wrapped function before returning,
      // otherwise it will return a promise which will cause Next.JS
      // not to display the error page properly

      return await GetServerSidePropsFn(ctx, payload);
    } catch (error) {
      return await getServerSidePropsWithError(
        ctx,
        error as Error,
        error.requestId
      );
    }
  };

  return wrappedFn;
};
export const getGermanDealTaxes = (price: number) =>
  price - price / VEHICLE_VAT;

const extractProlongationTrackingProps = (
  subs: Deal[],
  vehicle: GenericVehicleDetails,
  router: NextRouter
) => {
  const { dealId } = router.query;
  const subscription = (subs || []).filter((sub) => sub.id === dealId)[0];
  const { utm_source } = router.query;
  let isGreenDragon = !!subscription?.belongs_to_subscription_service;
  if (typeof isGreenDragon !== 'boolean') {
    isGreenDragon = isGreenDragon === 'true';
  }
  let daysLeft = null;
  if (subscription?.return_type) {
    const subReturnDate = new Date(subscription?.return_date);
    const today = new Date();
    daysLeft = dateDiff(today, subReturnDate);
  }

  return {
    deal_id: subscription?.id,
    car_brand: vehicle.brand.id,
    car_model: vehicle.model,
    customer_type: subscription?.type,
    success: true,
    source: utm_source as string,
    days_left_until_end_of_subscription: daysLeft,
    handover_date: subscription?.handover_appointment_date,
    return_date: subscription?.return_date,
    is_green_dragon: isGreenDragon,
  };
};

export const trackProlongationOfferConfirmed = (
  subs: Deal[],
  vehicle: GenericVehicleDetails,
  router: NextRouter
) => {
  const trackingProps = extractProlongationTrackingProps(subs, vehicle, router);

  retentionOfferConfimed(trackingProps);
};
