import { ExpandMore } from '@finn/design-system/icons/expand-more';
import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { OptionType, TooltipInputAdornment } from '@finn/ui-components';
import {
  Box,
  FormHelperText,
  makeStyles,
  MenuItem,
  NoSsr,
  Select,
  Theme,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl'; // We imported it this way because of https://github.com/vercel/next.js/issues/26184#issuecomment-864083198
// TODO enable eslint after adding Select component to DS
// eslint-disable-next-line
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { parseToHtml } from '~/utils/html';

// TODO design-system: refactor !important styles after migration to new typography APP-1443

interface IValidationState {
  error: boolean;
}

const useInputLabelStyles = makeStyles<Theme, IValidationState>(() => ({
  root: {
    top: '50%',
    left: 4,
    transform: 'translate(8px, -50%) scale(1)',
  },
}));

const useInputStyles = makeStyles<Theme, IValidationState>(() => ({
  root: {
    width: '100%',
    height: '100%',
    borderRadius: '2px',
    border: '1px solid',
  },
  input: {
    position: 'relative',
  },
}));

const useSelectStyles = makeStyles<Theme, IValidationState>((theme) => ({
  root: {
    height: '100%',
    padding: theme.spacing(0, 1),
    display: 'inline-flex',
    alignItems: 'center',
  },
  iconOpen: {
    transform: 'rotate(180deg)',
  },
}));

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    height: '56px',
    minHeight: '56px',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    display: 'flex',
  },
  menu: {
    maxHeight: '320px',
  },
  hiddenLabel: {
    visibility: 'hidden',
  },
  tooltip: {
    marginRight: theme.spacing(4),
  },
  roundedPaper: {
    boxShadow: theme.shadows[24],
    borderRadius: 2,
  },
}));

interface IProps<T> {
  placeholder: string;
  name: string;
  options: OptionType<T>[];
  autoFocus?: boolean;
  className?: string;
  onChange?(value: T): void;
  tooltipText?: string;
  showTooltipOnlyOnFocus?: boolean;
  icon?: React.ElementType;
}

const SelectDropdown = <T extends string | number>({
  name,
  options,
  placeholder,
  autoFocus,
  className,
  onChange,
  tooltipText,
  showTooltipOnlyOnFocus = true,
  icon = ExpandMore,
}: IProps<T>) => {
  const formik = useFormikContext<unknown>();
  const i18n = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const track = useTrackingStore((state) => state.track);

  const errorMessage = get(formik.errors, name) as string;
  const touched = !!get(formik.touched, name);
  const error = touched && !!errorMessage && !isOpen;
  const value = get(formik.values, name) || '';

  const classes = useStyles();
  const selectClasses = useSelectStyles({ error });
  const inputClasses = useInputStyles({ error });
  const inputLabelClasses = useInputLabelStyles({ error });

  const handleOpen = () => {
    formik.setFieldTouched(name, true);
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as T;
    formik.setFieldValue(name, newValue);
    formik.validateField(name);
    track(TrackingEventName.FORM_FIELD_ENTERED, {
      fieldName: name,
      once: true,
    });

    onChange?.(newValue);
  };

  const localizedErrorMessage = error
    ? parseToHtml(i18n.formatMessage({ id: errorMessage }))
    : '';

  const shouldDisplayTooltip = showTooltipOnlyOnFocus
    ? isFocused && Boolean(tooltipText)
    : Boolean(tooltipText);

  return (
    <Box>
      <FormControl variant="outlined" fullWidth className={classes.formControl}>
        <InputLabel
          disableAnimation
          shrink={false}
          className={cn({ [classes.hiddenLabel]: !!value })}
          classes={{
            ...inputLabelClasses,
            // TODO design-system: refactor !important styles after migration to new label component APP-1443
            root: cn(inputLabelClasses.root, '!body-16-regular', {
              '!text-red': error,
              '!text-black': !error,
            }),
          }}
          htmlFor={name}
        >
          {placeholder}
        </InputLabel>
        <NoSsr>
          <Select
            id={name}
            classes={selectClasses}
            className={className}
            labelId={name}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocus}
            MenuProps={{
              PaperProps: {
                classes: { rounded: classes.roundedPaper },
                elevation: 24,
              },
              className: classes.menu,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            value={value}
            input={
              <InputBase
                classes={{
                  ...inputClasses,
                  root: cn(inputClasses.root, '!border !border-solid', {
                    '!border-pearl': !error,
                    '!border-red': error,
                  }),
                }}
                className={className}
              />
            }
            endAdornment={
              shouldDisplayTooltip && (
                <TooltipInputAdornment
                  tooltipText={tooltipText || ''}
                  isDropdown={true}
                />
              )
            }
            IconComponent={icon}
            onChange={handleChange}
            onOpen={handleOpen}
            onClose={handleClose}
            onFocus={handleFocus}
            onBlur={handleBlur}
            data-testId={`select-${name}`}
          >
            {options?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </NoSsr>
      </FormControl>
      {error && (
        <FormHelperText error className="!text-red body-12-light mt-1">
          {localizedErrorMessage}
        </FormHelperText>
      )}
    </Box>
  );
};

export default SelectDropdown;
