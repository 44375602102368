import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@finn/atoms/Modal';
import { Button } from '@finn/design-system';
import { config } from '@finn/ui-utils';
import { Box, Grid, makeStyles, Theme } from '@material-ui/core';
import { Form, Formik } from 'formik';
import Cookies from 'js-cookie';
import React, { useEffect, useMemo, useState } from 'react';

import SelectDropdown from '~/components/FormElements/SelectDropdown';
import { useCurrentLocale } from '~/hooks/useCurrentLocale';
import allFeatureFlags from '~/localization/features2.json';
import DevModalSwitch from '~/modules/dev/DevModalSwitch';
import useActivateTestStripe from '~/modules/dev/hooks/useActivateTestStripe';
import useCosmicHelperActivation from '~/modules/dev/hooks/useCosmicHelperActivation';
import useCosmicS3 from '~/modules/dev/hooks/useCosmicS3';
import useDisableDirectCheckout from '~/modules/dev/hooks/useDisableDirectCheckout';

type VariantsType = { [key: string]: string };
const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  switchBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));

const DevModal: React.FC<{ open: boolean; onClose(): void }> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();
  const { isCosmicHelperActive, toggleCosmicHelper } =
    useCosmicHelperActivation();
  const { isTestStripeEnabled, toggleTestStripe } = useActivateTestStripe();
  const { isCosmicS3, toggleCosmicS3 } = useCosmicS3();
  const { isDirectCheckoutDisabled, toggleDirectCheckoutDisabling } =
    useDisableDirectCheckout();

  const [currentVariants, setCurrentVariants] = useState<
    Record<string, string> | undefined
  >();

  const { locale } = useCurrentLocale();
  const [flagList, disableFlagList, featureFlags] = useMemo(() => {
    const featureFlagsForLocale = allFeatureFlags[locale];
    const flagsWithMultipleBucket = Object.entries(
      featureFlagsForLocale || {}
    ).filter((item: [string, any]) => item[1]?.buckets?.length > 1);
    const otherFlags = Object.entries(featureFlagsForLocale || {}).filter(
      (item: [string, any]) => (item[1]?.buckets?.length || 0) < 2
    );

    return [
      flagsWithMultipleBucket.map((item) => item[0]),
      otherFlags.map((item) => item[0]),
      featureFlagsForLocale,
    ];
  }, [locale]);

  useEffect(() => {
    const val = {};
    flagList.forEach((name) => {
      val[name] = Cookies.get(`${locale}_${name}`);
    });
    setCurrentVariants(val);
  }, []);

  const switchExperiment = (name: string) => (variant: string) => {
    Cookies.set(`${locale}_${name}`, variant);
  };

  return (
    <Modal
      size="xl"
      open={open}
      disableAppEvent={true}
      onClose={() => {
        onClose();
        // if we close with esc, that esc also prevents the reloading. Adding a delay works
        window.setTimeout(() => {
          window.location.reload();
        }, 250);
      }}
      disableBrowserHistory
    >
      <ModalHeader>Dev Controls</ModalHeader>
      <ModalContent>
        <Box className={classes.switchBox}>
          <DevModalSwitch
            text="Content Helper"
            hotkey="Control+1"
            isActive={isCosmicHelperActive}
            onClick={toggleCosmicHelper}
          />
          <DevModalSwitch
            text="Test Stripe on Prod"
            hotkey="Control+2"
            helperText="Control+2: non-prod ENVs always use test stripe"
            isActive={isTestStripeEnabled}
            onClick={toggleTestStripe}
          />
          <DevModalSwitch
            text="Disable Direct Checkout"
            hotkey="Control+3"
            isActive={isDirectCheckoutDisabled}
            onClick={toggleDirectCheckoutDisabling}
          />
          {config.APP_STAGE === 'development' ? (
            <DevModalSwitch
              text="Use Cosmic Directly From S3 Backup"
              helperText="To toggle, Set COSMIC_USE_S3=yes (or no) in your .env variable"
              hotkey="Control+Shift+Option+9"
              isActive={isCosmicS3}
              onClick={toggleCosmicS3}
            />
          ) : null}

          {currentVariants && (
            <Formik<VariantsType>
              initialValues={currentVariants}
              onSubmit={() => {}}
            >
              {() => (
                <Form>
                  <Box my={3}>
                    <h5 className="global-t5-semibold">Experiments</h5>
                  </Box>
                  {flagList.map((name) => (
                    <Box mb={1} key={name}>
                      <Grid container alignItems="center">
                        <Grid item xs>
                          {name}
                        </Grid>
                        <Grid item xs>
                          <SelectDropdown
                            name={name}
                            placeholder=""
                            options={featureFlags[name].buckets.map(
                              (variant) => ({
                                label: variant,
                                value: variant,
                              })
                            )}
                            onChange={switchExperiment(name)}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                  <Box mt={5} mb={2}>
                    <h5 className="global-t5-semibold">Disabled Experiments</h5>
                  </Box>
                  <span className="body-14-light">
                    {disableFlagList.join(', ')}
                  </span>
                </Form>
              )}
            </Formik>
          )}
        </Box>
      </ModalContent>
      <ModalFooter>
        <Button
          className="w-full"
          onClick={() => {
            onClose();
            window.location.reload();
          }}
        >
          Close & Reload
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default React.memo(DevModal);
