import { CalendarDate, isSameDay, parseDate } from "@internationalized/date";
// this heper function returns metadata about is current cell selected, selections tart, end or in range
// this data later can be used to style calendar properly
export var getSelectionData = function(state, param) {
    var date = param.date, isSelected = param.isSelected, withOneClickRangeSelection = param.withOneClickRangeSelection;
    var ref;
    // library marks disabeld dates as not selected, even if they are in the range
    // to fix this we manually change the state of it
    if (!isSelected && "highlightedRange" in state && ((ref = state.highlightedRange) === null || ref === void 0 ? void 0 : ref.start)) {
        isSelected = state.highlightedRange && date.compare(state.highlightedRange.start) >= 0 && date.compare(state.highlightedRange.end) <= 0;
    }
    // default state for single calendar
    var selectionResult = {
        isSelectionStart: isSelected,
        isSelectionEnd: isSelected,
        isOnlySingleDaySelected: false,
        hasWrapperWithInSelection: false,
        hasWrapperSectionStart: false,
        hasWrapperSelectionEnd: false
    };
    if ("highlightedRange" in state) {
        // The start and end date of the selected range will have
        // an emphasized appearance.
        selectionResult.isSelectionStart = state.highlightedRange ? isSameDay(date, state.highlightedRange.start) : isSelected;
        selectionResult.isSelectionEnd = state.highlightedRange ? isSameDay(date, state.highlightedRange.end) : isSelected;
    }
    var isCellSelected = isSelected;
    if (withOneClickRangeSelection && (state === null || state === void 0 ? void 0 : state.value) && "start" in state.value) {
        var isInSelectedRange = state.value && date.compare(state.value.start) >= 0 && date.compare(state.value.end) <= 0;
        isCellSelected = isSelected || isInSelectedRange;
        selectionResult.hasWrapperWithInSelection = isCellSelected && !selectionResult.isSelectionStart && !selectionResult.isSelectionEnd;
    }
    selectionResult.hasWrapperWithInSelection = Boolean(isCellSelected && !selectionResult.isSelectionStart && !selectionResult.isSelectionEnd);
    selectionResult.hasWrapperSectionStart = Boolean(selectionResult.isSelectionStart && !selectionResult.isSelectionEnd);
    selectionResult.hasWrapperSelectionEnd = Boolean(selectionResult.isSelectionEnd && !selectionResult.isSelectionStart);
    return selectionResult;
};
export var toCalendarDate = function(date) {
    if (!date) {
        return undefined;
    }
    if (typeof date === "string") {
        var ref, ref1;
        return parseDate(date === null || date === void 0 ? void 0 : (ref = date.split) === null || ref === void 0 ? void 0 : (ref1 = ref.call(date, "T")) === null || ref1 === void 0 ? void 0 : ref1[0]);
    }
    return new CalendarDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
};
